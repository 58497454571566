<template>
  <div>
    <dialog-edit2 :value="value" @input="close">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ data.id ? m.title + ` №${data.code_doc}` : "Новый акт выполненных работ '" + m.title + "'" }} [{{ statusCurrent.text || "" }}]
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <a-btn-status v-if="getAccess(`documents.actWork.status`)" :items="statusItems" :data="data" @click="changeStatus($event)" :disabled="!canStatus && !isChanged" />
        <a-btn-send-code
          v-if="id && !readonly && data.status === 0 && false"
          :id="id"
          :api="'/accounting/doc/act_work'"
          @success="fetchData(id)"
          :title="'Подпись(SMS)'"
          :disabled="isChanged"
        />
        <v-btn v-if="id && !readonly && data.status === 0" color="primary" :disabled="isChanged" @click="sendAct()"> На проверку </v-btn>

        <v-spacer></v-spacer>
        <a-btn-delete v-if="id && !readonly && data.status === 0 && getAccess(`documents.actWork.delete`)" @click="removeDialogShow = true" />
        <a-btn-save v-if="!readonly && loaded" @click="submit()" :color="isChanged ? 'green' : 'primary'"></a-btn-save>

        <v-btn @click="close">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <confirm-dialog ref="confirm" />
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    hasData: { type: Boolean, default: false },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.documentActWork,
      api: "accounting/doc/act_work",
      statuses: this.$store.getters["config/get"].models.documentActWork.statuses,
      removeDialogShow: false,
      countChange: 0,
      suEditShow: false,
      __modelDoc: null,
      __modelReady: false,
      type: null,
      loaded: false,
      model: [],
      initData: null,
      suEditShow: false,
      isNew: false,
    };
  },
  computed: {
    readonly() {
      return this.data.status === 2;
    },
    canStatus() {
      return !this.loading && !this.isChanged && this.data.id;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
    statusItems() {
      if (!this.data?.status) return [];
      let items = [];
      let res = [];
      const statuses = this.statuses;
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next && statuses) {
        let arr = statusCur.next;
        res = statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }

      res.forEach((s) => {
        if (!this.getAccess("documents.actWork.status." + s.value, null, true)) {
          s.disabled = true;
        } else if (s.rules) {
          if (s.rules.formula) {
            let rule = eval(s.rules.formula);
            if (!(rule && rule(this.data))) s.disabled = true;
          }
        }
      });

      items = JSON.parse(JSON.stringify(res));
      return items;
    },
  },
  created() {
    this.loaded = false;
    this.type = this.initType || null;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true;
      ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    id(v) {
      if (v && this.isNew) {
        //this.loaded = false;
        this.reset();
        this.type = this.initType || null;
        this.updateData(v);
      }
    },
    value(v) {
      if (v) {
        this.isChanged = false;
        this.updateData(this.id);
        if (!this.id) {
          let d = this.initData;
          for (const name in d) {
            this.$set(this.data, name, d[name]);
            this.$set(this.errors, name, null);
          }
        }
      } else {
        this.isChanged = false;
        this.initData = null;
        this.data = {};
      }
    },

    "data.createdby_id"(v) {
      if (v && !this.id) {
        let model = this.model.find((el) => {
          return el.name == "vendor_id";
        });
        if (model) model["filters"] = { user_id: v || -1 };
        else console.log("not model founds");
      }
    },
  },
  methods: {
    reset() {
      this.isNew = false;
      this.type = null;
      this.initData = null;
    },
    async close() {
      if (this.isChanged) {
        let ok = await this.$refs.confirm.show({
          title: "Предупреждение",
          message: [`Вы хотите отменнить изменения и выйти без сохранения?`],
          okButton: "Да",
          cancelButton: "Нет",
        });
        if (!ok) return;
      }
      if (this.countChange) this.$emit("refresh");
      this.$emit("input");
    },
    inputData(d = {}) {
      console.log("inputData", d);
      this.initData = d;
      //console.log("result daata", this.data);
    },
    getModel(t = "default") {
      let type = this.data.status || 0;
      /**

       if (t) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || this.config));
      if (this.data.status == 2) config.fieldsRO = config.fields;
      this.watchModel = config?.watch || null;
      let model = this.calcModelConfig(config);
       */
      let config = JSON.parse(JSON.stringify(this.m?.config?.[type] || this.m?.config.default || {}));
      if (this.data.status == 2) config.fieldsRO = config.fields;
      let model = this.calcModelConfig(config);
      if (!false)
        model.forEach((el) => {
          if (el.name == "contract_id") {
            el.filters = { status: 2, contract_type: 3, side_2_vendor_id: this.data.vendor_id, object_id: this.data.object_id };
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("refresh");
      this.$emit("input");
    },
    afterSave(data, status) {
      this.isChanged = false;
      this.countChange++;
      //if (status && !this.isNew) this.$emit("input");
      this.$emit("create", data.id);
      //this.loaded = false;
      //this.updateData(data.id);
      // this.getModel();
      // if (!this.id) this.$router.push({ name: this.m.routeName, params: { id } });
    },
    async beforeSave() {
      this.isNew = !this.data.id;
      if (this.details) {
        this.data.data_table = this.details;
        this.data.parent_id = this.details[0].order_goods_id;
        this.data.parent_name = "AccountingDocOrderGoodModel";
      }
      return true;
    },
    afterFetchData(r) {
      this.getModel();
      this.loaded = true;
    },
    async sendAct() {
      let confirm = await this.$refs.confirm.show({
        title: "",
        message: ["Отправить Акт на проверку"],
        okButton: "Продолжить",
        cancelButton: "Отмена",
      });
      if (!confirm) return;
      await this.changeStatus({ value: 1 });
      this.fetchData(this.id);
    },
    async changeStatus(status) {
      this.countChange++;
      let id = this.data.id;
      if (!id) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Что-то пошло не так....",
        });
        return;
      }
      this.loading = true;
      let data = { id, status: status.value };
      let response = await this.$axios.post(this.api, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
  },
};
</script>
